.color-scheme-light {
    --button-blue-border-color: #4077D6;
    --button-blue-hover-color: rgb(77, 133, 230);
    --button-white-hover-color: #FCFCFC;
    --button-white-color: #FFFFFF;
    --button-white-border-color: #DBDEDF;
    --button-text-color: #6B6D73;
    --button-white-text-color: #91989B;
    --button-blue-disabled-hover-color: #F8FAFF;
    --button-blue--disabled-text-color: #82858B;
    --button-blue--disabled-border-color: #DCDEDF;
    --button-blue--disabled-background-color: #FBFBFB;

    --radio-circle-border-color: #C6C6C6;
    --radio-circle-label-color: #2D3641;

    --сheckbox-label-color: #9EA0A6;
    --сheckbox-border-color: #E8EAEB;
    --checkbox-checkmark-color: #2E3640;

    --input-border-color: #DBDEDF;
    --placeholder-color: rgba(54, 59, 68, 0.5);
}
