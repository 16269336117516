@import "controls/variables_controls_light.css";
@import "./icons/variables_icons_light.css";
@import "./text/variables_text_light.css";
@import "./popups/variables_popups_light.css";

.color-scheme-light {
    --white-color: #FFF;
    --black-color: #000;


    --gray-color: #CCCCCC;
    --light-gray-color: #92989B;
    --silver-gray-color: #C1C1C1;
    --light-blue-color: #4C8BF7;
    --light-green-color: #C3F07F;

    --border-area-grey-color: #F4F4F4;
    --border-light-gray-color: #C5C5C5;

    --light-gray-background-color: #F5F5F5;

    --white-half-opacity-color: rgba(255, 255, 255, 0.5);
}
