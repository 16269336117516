/* You can add global styles to this file, and also import other style files */
@import "./assets/css/variables_light_color.css";
@import "./assets/css/global_styles.css";

@font-face {
    font-family: 'PT Sans';
    src: url('/assets/fonts/PTSans-Regular.ttf') format('woff');
    font-display: swap;
}

* {
    box-sizing: border-box;
}

body {
  margin: 0;
  line-height: 18px;
  background-color: var(--white-color, #FFF);
}

html,
body {
  height: 100%;
  font-family: "PT Sans", sans-serif;
}

