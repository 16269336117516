.popup {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--popup-border-color);
    background-color: var(--white-color);
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
    color: var(--text-dark-color);
    z-index: 11;
}

.popup__title {
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 20px;
}

.popup__description {
    font-size: 15px;
    line-height: 19px;
}
