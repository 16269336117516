.color-scheme-light {
    --text-dark-color: #363B44;
    --text-gray-color: #92989B;
    --text-dark-gray-color: #6B6D72;
    --text-regular-color: #2E3641;
    --title-black-color: #333941;
    --text-green-color: #26BC8C;
    --subtitle-gray-text-color: #91959B;
    --subtitle-darkgray-text-color: #91989C;
    --error-text-color: #DA8089;
}
